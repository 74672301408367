@font-face {
    font-family: "NTR";
    font-weight: 200;
    font-display: fallback;
    src: url("../../public/fonts/NTR-Regular.ttf") format("truetype");
}

.error-page {
    font-family: 'NTR', sans-serif;
    background-color: var(--navy); /* Dark navy background */
    color: var(--lightest-slate); /* Light slate color for text */
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.error-page section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 16px;
    background-color: var(--navy);
}

.error-page .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    max-width: 600px;
    text-align: center;
    min-height: 100vh;
}

.error-page h2 {
    font-size: 9rem;
    color: var(--pink-bright);
    margin-bottom: 0.5em;
}

.error-page p {
    font-size: 22px;
    color: var(--lightest-slate);
}

.error-page a {
    font-size: 18px;
    font-weight: bold;
    background-color: transparent; /* No background for outlined button */
    color: var(--yellow); /* Text color set to yellow */
    padding: 12px 24px;
    border: 2px solid var(--yellow); /* Yellow border */
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.error-page a:hover {
    background-color: var(--yellow); /* Yellow background on hover */
    color: var(--navy); /* Text color changes on hover */
}

@media only screen and (max-width: 600px) {
    .error-page h2 {
        font-size: 6rem;
    }

    .error-page p {
        font-size: 18px;
    }

    .error-page a {
        font-size: 16px;
        padding: 10px 20px;
    }
}