/* src/styles/PhotoManagerPage.css */

.PhotoManager {
    background-color: var(--navy);
    min-height: 100vh;
    padding: 2rem;
    color: white;
  }
  
  .form {
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .h2 {
    color: white;
    margin-bottom: 1rem;
  }
  
  .Button {
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--pink-bright);
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
  }
  
  .Button:hover {
    background-color: #e0c70972;
  }
  
  .message-container {
    height: 50px;
    margin-bottom: 1rem;
  }
  
  .message {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
  }
  
  .message.error {
    background-color: #f44336;
  }
  
  .message.success {
    background-color: #4CAF50;
  }
  
  .photo-manager {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logout-button {
    padding: 5px 10px;
    border: none;
    background-color: #f44336;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
  }
  
  .upload-section,
  .photos-section,
  .current-photo-section {
    margin-top: 2rem;
  }
  
  .photos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .photo-card {
    position: relative;
  }
  
  .photo {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .delete-button {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 5px 10px;
    border: none;
    background-color: rgba(244, 67, 54, 0.8);
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: rgba(244, 67, 54, 1);
  }
  
  @media only screen and (max-width: 600px) {
    .form,
    .photo-manager {
      padding: 1rem;
    }
  
    .input,
    .Button {
      padding: 8px 15px;
    }
  
    .h2,
    .upload-section h3,
    .photos-section h3,
    .current-photo-section h3 {
      font-size: 1.2rem;
    }
  
    .photos-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  }
  
  .settings-section {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .toggles {
    display: flex;
    flex-direction: column;
  }
  
  .toggle-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Switch Styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-right: 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0; left: 0;
    right: 0; bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px; width: 18px;
    left: 3px; bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  .switch input:checked + .slider {
    background-color: var(--pink-bright);
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px var(--pink-bright);
  }
  
  .switch input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .switch-label {
    font-weight: bold;
  }
  
  /* Timezone Display Styles */
  .timezone-display {
    margin-top: 10px;
    font-size: 1rem;
    color: #f0f0f0;
    text-align: center;
  }
  
  /* Current Photo Display Styles */
  .current-photo-section {
    margin-top: 2rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .current-photo-section h3 {
    margin-bottom: 1rem;
  }
  
  .current-photo {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
  }