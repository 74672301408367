#credits {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--navy);
  padding: 0;
  box-sizing: border-box;
}

.credits-wrapper {
  width: 70%;
  display: flex;
  justify-content: flex-start;
}

.ending-credits {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  text-align: center; 
  font-size: 16px;
  padding: 30px 0;
  margin-left: -9%; /* Set this percentage to control the alignment on larger screens */
}

/* Mobile Styles */
@media (max-width: 600px) {
  .ending-credits {
    margin-left: 0; /* Set margin-left to zero on mobile */
    text-align: center; /* Optional: Center text on mobile if desired */
  }

  .credits-wrapper {
    width: 100%; /* Full width on mobile */
  }
}