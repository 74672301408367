.Manage {
    background-color: var(--navy);
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow: hidden;
    position: relative;

    min-height: 100vh;
    min-width: 100%;
}

.Manage #progressBarContainer {
    display: none !important;
}

.top-left-button {
    position: absolute;
    top: 20px; /* Adjust the space from the top */
    left: 20px; /* Adjust the space from the left */
    z-index: 1000; /* Ensure it's above other elements if needed */
}

html, body {
    margin-top: 0;
    height: auto;
    max-width: 100vw;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
}
.form {
    position: relative;
    padding: 2rem; /* Adjusted for a balanced look */
    width: 100%; /* Take full width to match projects container */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .Manage {
        padding-left: 0; 
        padding-right: 0;
        width: 90%; /* Responsive width for smaller screens, matching projects */
        margin-left: 5%; /* Center footer on small screens */
        margin-right: 5%;
    }
    .form {
        padding: 1rem;
        width: 100%; /* Responsive width for smaller screens */
    }
    .Manage, .form {
        padding: 0;
        margin: 0 auto; /* Center-align the form within its container */
        width: 100%; /* Use full width for mobile devices */
        max-width: 100%; /* Prevent any overflow issues */
    }
    .input, .first, .last, .Button {
        width: 100%; /* Ensure inputs and buttons extend full width */
    }
}

.form {
    position: relative;
    padding: 2rem; /* Adjusted for a balanced look */
    min-width: 300px;
    max-width: 80%; /* Limit max width for better control */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.h2 {
    color: white;
    margin-bottom: 1rem;
}

.name-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Take full width of form */
    margin-bottom: 1rem;
}

.first, .last {
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 48%; /* Slightly less than half to fit side by side */
    background-color: var(--light-navy);
    color: var(--pink-bright);
}

.input {
    width: 100%; /* Full width for email input */
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: var(--light-navy);
    color: var(--pink-bright);
    margin-bottom: 1rem;
}

.Button {
    padding: 10px 20px;
    margin: 10px 0;
    width: 100%; /* Full width for button */
    border-radius: 10px;
    background-color: var(--pink-bright);
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Button:hover {
    background-color: #e0c70972;
}

@media only screen and (max-width: 600px) {
    .Manage {
        padding-left: 0;
        padding-right: 0;
        width: 100%; /* Adjust width to use the full screen on smaller devices */
        margin-left: 0; /* Remove additional margins */
        margin-right: 0;
        overflow: scroll;
    }
    .top-left-button {
        top: 10px; /* Less space from the top */
        left: 10px; /* Less space from the left */
        font-size: 14px; /* Optionally reduce the font size */
    }
    .form {
        padding: 1rem;
        width: 100%; /* Ensure the form uses full width */
        min-width: unset; /* Remove min-width setting */
    }
    .input, .first, .last, .Button {
        padding: 8px 15px; /* Adjust padding for smaller screens */
    }

        h2, .input, .first, .last, .Button, p {
            font-size: smaller; /* Reduce the font size for smaller devices */
        }
}


.message {
    margin-top: 1rem;
    padding: 10px;
    border-radius: 5px;
    color: #fff; /* White text */
    text-align: center;
    background-color: #f44336; /* Red for error messages */
    animation: fadeInOut 5s linear forwards;
}


@keyframes fadeInOut {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

.message.error {
    background-color: #f44336; /* Red background for errors */
}

.message.success {
    background-color: #4CAF50; /* Green background for successes */
}

.message-container {
    height: 50px; /* Adjust based on typical message height */
    margin-bottom: 1rem; /* Space below the reserved area */
}

.message {
    height: 100%; /* Fill the reserved container */
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-align: center;
    background-color: #f44336;
    animation: fadeInOut 5s linear forwards;
}