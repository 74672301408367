@font-face {
  font-family: "NTR";
  font-weight: 200;
  font-display: fallback;
  src: url("../public/fonts/NTR-Regular.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
  background-color: #000;
}

@media only screen and (max-width: 600px) {
  .App {
    flex-direction: column;
    padding: 40px 24px;
    box-sizing: border-box;
  }

  #content, .App {
    padding: 0; 
}

  #content {
    padding: 10px 10px;
    width: 100%;
  }

  .section-header {
    width: unset;
  }

  .section-header::after {
    display: none;
  }

  .section-title {
    font-size: 32px;
  }
}