#projects-page {
  position: static;
  padding-left: 15%;
  padding-right: 25%;
  min-height: 60vh;
  background-color: var(--navy);
  overflow: hidden;
}

.projects-grid-page {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-gap: 10px;
}

@media (max-width: 1080px) {
  .projects-grid-page {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.projects-card-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 1.75rem;
  background-color: var(--light-navy);
  border-radius: 16px;
  transition: background-color 0.3s;
}

.projects-card-page:hover {
  background-color: var(--lightest-navy);
}

.card-header-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.folder-icon-page {
  color: var(--pink-bright);
}

.github-icon-page {
  margin-top: 6px;
  transition: color 0.3s;
}

.github-icon-page:hover {
  color: var(--pink-bright);
}

.card-title-page {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-desc-page {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.card-tech-page {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 16px;
}

.carousel-page {
  padding-top: 10%;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 7%;
}

.carousel-inner-page {
  height: 380px;
  border-radius: 24px;
  overflow: hidden;
}

.carousel-inner-page:hover {
  transform: scale(1.01);
  background: var(--lightest-navy);
}

.carousel-img-page {
  object-fit: fill;
  height: 100%;
  opacity: 0.3;
}

.carousel-caption-page {
  font-family: "NTR", sans-serif;
  max-width: 500px;
  right: 25% !important;
  left: 25% !important;
  top: 25% !important;
  bottom: unset !important;
  user-select: none;
}

.carousel-caption-page h3 {
  font-size: 38px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.carousel-caption-page p {
  font-family: "NTR", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: var(--lightest-slate);
}

.carousel-caption-page p.techStack-page {
  font-family: "NTR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: var(--pink-bright);
}

.carousel-indicators .active {
  background-color: var(--pink-bright);
}

@media only screen and (max-width: 600px) {
  #projects-page {
    padding-left: unset;
    padding-right: unset;
  }

  .carousel-page {
    display: none;
  }
}