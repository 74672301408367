img {
    object-fit: cover; /* or contain, depending on your design needs */
    height: auto;
  }

#GalleryPage{
    background-color: var(--navy);
}

.gallery-container {
    background-color: var(--navy); /* Container-specific color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
    padding: 20px; /* Inner padding */
  }