#projects {
  position: static;
  padding-left: 15%;
  padding-right: 25%;
  min-height: 60vh;
  padding-top: 20px;
  margin-top: 50px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  grid-gap: 10px;
}
@media (max-width: 1080px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.projects-card {
  background-color: var(--light-navy);
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  background-color: var(--light-navy);
  border-radius: 16px;
}

.projects-card:hover {
  background-color: var(--lightest-navy);
}

.card-header {
  background-color: transparent;
  margin-top: -20px;
  display: flex;
  padding: 1.3em 0 !important;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: none !important;
}

.projects-card .card-header {
  background-color: transparent !important;
}
.folder-icon {
  color: var(--pink-bright);
}

.github-icon {
  margin-top: 6px;
}
.github-icon:hover {
  color: var(--pink-bright);
}

.open-icon {
  margin-left: 10px;
}

.card-title {
  font-family: "NTR", sans-serif;
  color: var(--lightest-slate);
  font-size: 24px;
  font-weight: bold;
}

.card-desc {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 18px;
  padding-bottom: 50px;
}

.card-tech {
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-size: 16px;
}

/* Spotlight carousel */
.carousel.slide {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 0px;
  padding-bottom: 7%;
}

.carousel-inner {
  height: 380px;
  border-radius: 24px;
  /*box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);*/
}

.carousel-inner:hover {
  transform: scale(1.01);
  background: var(--lightest-navy);
  
}

.carousel img {
  object-fit: fill !important;
  height: 450px;
  height: 100%;
  opacity: 0.3;
}

.carousel-caption {
  font-family: "NTR", sans-serif;
  max-width: 500px;
  right: 25% !important;
  left: 25% !important;
  top: 25% !important;
  bottom: unset !important;
  user-select: none;
}

.carousel-caption h3 {
  font-size: 38px;
  font-weight: bold;
  color: var(--lightest-slate);
}

.carousel-caption p {
  font-family: "NTR", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: var(--lightest-slate);
}

.carousel-caption p.techStack {
  font-family: "NTR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: var(--pink-bright);
}

.carousel-indicators .active {
  background-color: var(--pink-bright);
}

.portfolio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-navy);
  border-radius: 16px;
  padding: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.portfolio-button:hover {
  background-color: var(--lightest-navy);
}

.portfolio-button .card-title {
  color: var(--lightest-slate);
  font-size: 27px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-icon {
  margin-left: 8px;
  font-size: 24px;
  transition: transform 0.3s ease;
  color: var(--pink-bright);
}

.portfolio-button:hover .arrow-icon {
  transform: translateX(5px);
}

@media only screen and (max-width: 600px) {
  #projects {
    height: unset;
    padding-left: unset;
    padding-right: unset;
  }
  .carousel {
    display: none;
  }
}
