#socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
  height: 100vh;
  background-color: var(--navy);
  color: var(--light-slate);
  text-align: center;
}

.profile-photo {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 5vh;
  border: 3px solid var(--pink-bright);
}

.socials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 90vw;
}

.intro-text {
  font-family: "NTR", sans-serif;
  font-size: 2.4vh;
  color: var(--light-slate);
  margin-bottom: 3vh;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--pink-bright);
  color: white;
  text-decoration: none;
  padding: 1.5vh 2.5vw;
  margin: 1vh 0;
  border-radius: 50px;
  font-size: 1.8vh;
  font-family: "NTR", sans-serif;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
}

.social-link .social-icon {
  margin-right: 1vw;
}

.social-link:hover {
  background-color: var(--pink-bright);
  transform: scale(1.05);
}

.top-left-button {
  position: absolute;
  top: 2vh;
  left: 2vw;
  z-index: 1000;
}

.home-button {
  text-decoration: none;
  color: var(--pink-bright);
  font-size: 1.8vh;
  transition: color 0.3s;
}

.home-button:hover {
  color: var(--light-slate);
}

.name {
  font-size: 3.2vh;
  color: var(--light-slate);
  margin: 1vh 0;
}

.website-button-container {
  margin: 2vh 0;
}

.website-button {
  display: inline-block;
  padding: 1vh 2vw;
  border: 2px solid var(--pink-bright);
  border-radius: 25px;
  background-color: transparent;
  font-family: "NTR", sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.website-button:hover {
  background-color: var(--pink-bright);
  color: var(--navy);
}

/* Responsive design */
@media only screen and (max-width: 600px) {
  .profile-photo {
    width: 20vh;
    height: 20vh;
  }

  .social-link {
    font-size: 1.6vh;
    padding: 1vh 2vw;
    margin: 2vh 0;
  }

  .intro-text {
    font-size: 2vh;
  }
}