#intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15%;
  padding-top: 0.02%;
  min-height: 80vh;
  position: relative;
}

.Typist {
  color: var(--pink-bright);
  text-align: center;
  font-size: 86px;
  margin-top: -30px;
}
.Typist .Cursor--blinking {
  padding-left: 5px; 
  color: var(--pink-bright);
  vertical-align: middle;
  position: relative;
  top: -15px;
  animation: blink 1s step-end infinite;
}
.intro_pic img {
  max-width: 170px;
  padding-bottom: 10%;
  height: auto;
  border-radius: 10px;
  margin-left: 70px;
  border: none;
}

.intro-title {
  font-family: "NTR", sans-serif;
  font-size: 86px;
}
.intro-name {
  font-family: "NTR", sans-serif;
  color: var(--pink-bright);
  font-weight: bold;
  font-size: 86px;
}

.intro-subtitle {
  margin-top: -36px;
  font-family: "NTR", sans-serif;
  color: var(--slate);
  font-weight: 300;
  font-size: 48px;
}

.intro-desc {
  color: var(--slate);
  font-family: "NTR", sans-serif;
  font-size: 22px;
  max-width: 40%;
  padding-bottom: 50px;
  letter-spacing: normal; /* Ensure default spacing */
  word-spacing: normal; /* Ensure default word spacing */
}

.intro-contact {
  color: var(--pink-bright);
  font-size: 22px;
  font-weight: bolder;
  font-family: "NTR", sans-serif;
  padding: 10px 30px;
  cursor: pointer;
  border: 4px solid var(--pink-bright);
  border-radius: 4px;
}

.intro-contact:hover {
  background: linear-gradient(var(--lightest-navy), var(--lightest-navy)) padding-box,
              linear-gradient(45deg, darkblue, var(--pink-bright)) border-box;
  border-radius: -4px;
  border: 4px solid transparent;
}

b {
  font-size: 17px;
}


.scroll-indicator {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 5;
}

.scroll-indicator.fade-out {
  opacity: 0;
}

.scroll-indicator .arrow {
  width: 20px;
  height: 20px;
  border-left: 2px solid var(--pink-bright);
  border-bottom: 2px solid var(--pink-bright);
  transform: rotate(-45deg);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(-45deg);
  }
  40% {
    transform: translateY(5px) rotate(-45deg);
  }
  60% {
    transform: translateY(3px) rotate(-45deg);
  }
}

@media only screen and (max-width: 600px) {

  #intro {
    min-height: unset;
    padding-left: unset;
    margin: 0;
    align-items: center;
  }

  .intro-title {
    font-size: 46px;
  }
  .intro-name {
    font-size: 46px;
  }

  .Typist {
    font-size: 36px;
  }

  .intro-subtitle {
    margin: 0;
    font-size: 28px;
    text-align: center;
  }

  .intro-desc {
    font-size: 18px;
    text-align: center;
    max-width: unset;
    padding-bottom: 40px;
  }

  .intro-contact {
    font-size: 16px;
    align-self: center;
    margin-left: 30%;
  }

  .scroll-indicator {
    display: none;
  }

}

