#three-d-canvas-container {
  padding-bottom: 20px;
  width: 250px;
  height: 250px;
}

#three-d-canvas {
  width: 100%; 
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  #three-d-canvas-container {
    display: none; /* Hide canvas on small screens */
  }
}