#header {
    padding-left: 2%;
    padding-top: 1%;
    padding-right: 25%;
    white-space: nowrap;
  }
  
  .Typist {
    color: var(--lightest-slate);
    text-align: left;
    font-size: 50px;
    margin-top: -30px;
    display: inline-block;

  }
  .Typist .Cursor--blinking {
    color: var(--pink-bright);
    padding-left: 4px;
  }
  
  .header-static {
    text-align: left;
    font-family: "NTR", sans-serif;
    font-size: 50px;
    color: var(--pink-bright);
    display: inline-block;
    position: relative; /* Needed for precise alignment */
    letter-spacing: 0.1em; /* Add spacing between characters */
  }
  
  .header-cursor {
    display: inline-block;
    position: relative;
    top: -1px; /* Move the cursor up to align with ~ / */
    font-size: 42px;
    color: var(--pink-bright);
  }
  .header-blinker {
    text-align: left;
    font-family: "NTR", sans-serif;
    color: var(--pink-bright);
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 0.1em;
    display: flex;
  }
  
  
  @media only screen and (max-width: 600px) {
  
    #header {
      min-height: 100;
      padding-left: unset;
      margin: 0.5;
      align-items: left;
      padding-bottom: 5%;
    }
  
    .header-static {
      font-size: 46px;
    }
    .header-blinker {
      font-size: 46px;
    }
  
    .Typist {
      font-size: 36px;
    }
  
  }
  